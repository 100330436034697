import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions06 = () => {
    const intl = useIntl();
    const messages = intl.messages;

    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">
                <div className="terms-and-conditions">
                    <div className="container">
                        <p className="terms-and-conditions__title">
                            <strong>
                                和泰產物保險股份有限公司履行個人資料保護法告知事項
                            </strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>本公司（和泰產物保險股份有限公司）依據個人資料保護法第 6 條第 2 項、第 8 條第 1 項(如為間接<br/><br/>蒐集之個人資料則為第 9 條第 1 項)之規定，向 台端告知下列事項，敬請台端詳閱：</p>
                            <p>一、 蒐集之目的：</p>
                            <p>(一)財產保險(依保險法令規定辦理之財產保險相關業務)。</p>
                            <p>(二)人身保險(依保險法令規定財產保險業經主管機關許可辦理之人身保險相關業務)。</p>
                            <p>(三)消費者保護、契約、類似契約或其他法律關係事務、執行其他經營合於營業登記項目或組織章程所定之業務、調查、統計與研究分析、個人資料之合法交易業務。</p>
                            <p>二、類別：</p>
                            <p>一般個資（包括但不限於姓名、身分證統一編號、護照號碼、出生年月日、住址、聯絡方式、婚姻、家庭、教育、職業、財務情況、位置資料、網路瀏覽器cookies與識別碼(IP位址)、行動及網路媒體資訊、及其他得以直接或間接方式識別該個人之資料，詳如相關業務申請書或契約書內容）、特種個資（病歷、醫療、基因、生理、心理及健康檢查等，在法令許可或台端書面同意範圍內者）。</p>
                            <p>三、個人資料來源：</p>
                            <p>(一)要保人/被保險人。</p>
                            <p>(二)司法警憲機關、本公司委託協助處理理賠或服務之公證人或作業委外處理機構。</p>
                            <p>(三)當事人之法定代理人、輔助人。</p>
                            <p>(四)各醫療院所。</p>
                            <p>(五)與第三人共同行銷、交互運用客戶資料、合作推廣等關係、或於本公司各項業務內所委託往來之第三人。</p>
                            <p>四、個人資料利用之期間、對象、地區及方式</p>
                            <p>(一)期間：因執行業務所必須及法令規定應為保存之期間，或至台端通知本公司刪除或停止處理或利用該個人資料之日止。</p>
                            <p>(二)對象：</p>
                            <p>1.本公司、與本公司簽訂合作推廣契約之保險代理人/保險經紀人、因辦理財產保險相關業務需要之第三方。</p>
                            <p>2.本公司之總公司、分公司、通訊處、所屬集團之國內外關聯企業、中華民國產物保險商業同業公會、中華民國人壽保險商業同業公會、財團法人保險事業發展中心、財團法人保險安定基金、財團法人住宅地震保險基金、財團法人汽車交通事故特別補償基金、財團法人金融消費評議中心、財團法人金融聯合徵信中心、財團法人聯合信用卡中心、台灣票據交換所、財金資訊公司、關貿網路股份有限公司、中央健康保險局、業務委外機構、與本公司有再保業務往來之公司、依法有調查權機關或金融監理機關。</p>
                            <p>3.本公司所屬集團和泰汽車股份有限公司及其關係企業、台灣地區授權經銷商及業務合作關係第三人。包括國都汽車、北都汽車、桃苗汽車、中部汽車、南都汽車、高都汽車、蘭揚汽車、東部汽車、和航汽車、中誠汽車、和榮汽車、長源汽車、和泰豐田物料運搬、和泰聯網、和泰移動服務、和潤企業、和運租車、和雲行動服務、車美仕、興聯科技、和泰興業、國瑞汽車、和展投資、和昭實業、和安保代、和全保代、和勁行銷、和泰服務行銷、泛台貨櫃運輸、益台通運、樺台通運、快順倉儲交通、大全彩藝、旭和螺絲、台灣橫濱輪胎、台灣柏原和泰、多羅滿汽車零配件等關係企業及本公司共同行銷、交互運用客戶資料、合作推廣業務之公司。</p>
                            <p>(三)地區： </p>
                            <p>1.上述對象所在之地區。</p>
                            <p>2.因辦理財產保險相關業務需要之第三方地區或國家。</p>
                            <p>(四)方式：合於前揭法令規定之利用方式，包含但不限於必要之國際傳輸等。</p>
                            <p>五、依據個資法第三條規定，台端就本公司保有台端之個人資料得行使之權利及方式：</p>
                            <p>(一)得向本公司行使之權利︰</p>
                            <p>1.向本公司查詢、請求閱覽或請求製給複製本。</p>
                            <p>2.向本公司請求補充或更正。</p>
                            <p>3.得向本公司請求停止蒐集、處理或利用及請求刪除。</p>
                            <p>(二)行使權利之方式：</p>
                            <p>1.以書面或其他日後可供證明之方式。</p>
                            <p>2.台端可以撥打下列客服專線 0800-880-550 理賠專線 0800-077-568</p>
                            <p>六、台端不提供個人資料所致權益之影響（個人資料由當事人直接蒐集之情形適用）：</p>
                            <p>台端若未能提供相關個人資料時，本公司將可能延後或無法進行必要之審核及處理作業，因此可能婉謝承保、遲延或無法提供台端相關服務或給付。</p>
                            <p>台端倘不同意本告知事項之一部或全部，或有其他疑義者，得隨時以適當方式告知本公司處理與說明，若未告知則將視為同意本告知事項。</p>
                            <p>七、告知事項之查閱: </p>
                            <p>本公司另將本告知事項登載於本公司網站(<a href={messages["common.website.url"]} target="_blank" rel="noreferrer">{messages["common.website.url"]}</a>)，隨時可供台端查閱。</p>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions06;
